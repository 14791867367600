@import url(https://rsms.me/inter/inter.css);
html {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", system-ui, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  font-size: 16px;
  color: #171e26;
}

html code {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

html .ant-form-item-label > label {
  /*  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 0.75rem;
  font-weight: 500;*/
  color: hsla(212, 25%, 12%, 0.7);
  margin-top: 4px;
}

html .ant-form-item {
  margin-bottom: 12px;
}

html .ant-input,
html .ant-input-affix-wrapper {
  background-color: #f4f7fa;
  border-color: transparent;
  border-radius: 6px;
  transition: 0.2s ease background-color;
  padding: 8px 16px;
}

html .ant-input:focus,
html .ant-input-affix-wrapper:focus,
html .ant-input-affix-wrapper:focus-within {
  background-color: white;
  border-color: #9dadc2;
  box-shadow: none;
}

html .ant-input:hover,
html .ant-input-affix-wrapper:hover,
html .ant-input-affix-wrapper:hover .ant-input {
  background-color: white;
  border-color: #9dadc2;
  box-shadow: none;
}

html .ant-input::-webkit-input-placeholder {
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input:-ms-input-placeholder {
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input::-ms-input-placeholder {
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input::placeholder {
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-popover-inner-content,
html .ant-dropdown,
html .ant-dropdown-menu-item,
html .ant-dropdown-menu-submenu-title {
  color: #171e26;
}

html .codex-editor__redactor {
  padding-bottom: 0 !important;
}

html .no-loader .codex-editor__loader {
  display: none;
}

html .collapsed-rte {
  white-space: nowrap;
  overflow: hidden;
}

html .collapsed-rte .ce-block:not(:first-of-type) {
  display: none;
}

html .collapsed-rte .ce-block:first-of-type .ce-paragraph.cdx-block {
  overflow: hidden;
  text-overflow: ellipsis;
}

html .ce-paragraph[data-placeholder]:empty::before {
  cursor: text;
  transition: 0.2s ease opacity;
}

html
  .codex-editor--empty
  .ce-block:first-child
  .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0.5;
}

html .ce-block b {
  font-weight: 600;
}

